export const products = [
    {
      id: 1,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
    {
      id: 2,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
    {
      id: 3,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
    {
      id: 4,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
    {
      id: 5,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
    {
      id: 6,
      img: "https://static.wixstatic.com/media/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png/v1/fill/w_650,h_1400,al_c/bb1bd6_06398b9a320b40c28f4c4250965d3625~mv2.png",
      link: "https://github.com/johnrrodgers?tab=repositories",
    },
  ];